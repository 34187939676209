// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/CaviarDreams.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/NewYork.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@font-face {
  font-family: "CaviarDreams";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
@font-face {
  font-family: "NewYork";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
.CVWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.cv-image {
  width: 33%; /* Réduit la taille à 50% */
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/CV/CV.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACUd;EACE,2BAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ADRJ;ACWE;EACE,sBAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ADTJ;AAVA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AAYF;;AATA;EACE,UAAA,EAAA,2BAAA;EACA,YAAA;AAYF","sourcesContent":["@import '../../variables';\n\n.CVWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f4f4f4;\n}\n\n.cv-image {\n  width: 33%; /* Réduit la taille à 50% */\n  height: 100%;\n}","// _variables.scss\n\n$color-background:#B1B5C8;\n$color-hover: #FEFFBE;\n$color-text: #503F47;\n$color-dark: #000411;\n\n\n\n  \n  @font-face {\n    font-family: 'CaviarDreams';\n    src: url('../src/assets/fonts/CaviarDreams.ttf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n\n  @font-face {\n    font-family: 'NewYork';\n    src: url('../src/assets/fonts/NewYork.otf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n  // Variable pour utiliser la police dans tout le projet\n  $font-primary: 'NewYork', sans-serif;\n  $font-secondary: 'CaviarDreams', sans-serif;\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
