// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/CaviarDreams.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/NewYork.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "CaviarDreams";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
@font-face {
  font-family: "NewYork";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
.PresentationWrapper {
  flex: 0 1 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #B1B5C8;
}

.PresentationWrapper p {
  font-size: calc(10px + 8 * (100vw - 375px) / 1065);
  line-height: 1.6;
  color: #503F47;
  font-family: "CaviarDreams", sans-serif;
  margin: 0 30px;
}

@media (max-width: 640px) {
  .PresentationWrapper p {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/presentation/presentation.scss"],"names":[],"mappings":"AAUE;EACE,2BAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACTJ;ADYE;EACE,sBAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACVJ;AATA;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBDNc;ACiBlB;;AAPE;EACE,kDAAA;EACA,gBAAA;EACA,cDXS;ECYT,uCDSe;ECRf,cAAA;AAUJ;;AAPE;EACE;IACE,eAAA;EAUJ;AACF","sourcesContent":["// _variables.scss\n\n$color-background:#B1B5C8;\n$color-hover: #FEFFBE;\n$color-text: #503F47;\n$color-dark: #000411;\n\n\n\n  \n  @font-face {\n    font-family: 'CaviarDreams';\n    src: url('../src/assets/fonts/CaviarDreams.ttf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n\n  @font-face {\n    font-family: 'NewYork';\n    src: url('../src/assets/fonts/NewYork.otf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n  // Variable pour utiliser la police dans tout le projet\n  $font-primary: 'NewYork', sans-serif;\n  $font-secondary: 'CaviarDreams', sans-serif;\n\n\n\n","@import '../../variables';\n\n.PresentationWrapper {\n    flex: 0 1 30%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    background-color: $color-background;\n  \n  }\n  \n  .PresentationWrapper p {\n    font-size: calc(10px + (18 - 10) * ((100vw - 375px) / (1440 - 375)));\n    line-height: 1.6;\n    color: $color-text;\n    font-family: $font-secondary;\n    margin: 0 30px;\n  }\n  \n  @media (max-width: 640px) {\n    .PresentationWrapper p {\n      font-size: 16px;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
