// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/CaviarDreams.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/NewYork.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "CaviarDreams";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
@font-face {
  font-family: "NewYork";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
.contactWrap {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #B1B5C8;
}

.contactForm {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.contact-form {
  width: 100%;
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/pages/contact/contact.scss"],"names":[],"mappings":"AAUE;EACE,2BAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACTJ;ADYE;EACE,sBAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACVJ;AATA;EACE,aAAA;EACA,aAAA;EACA,YAAA;EACA,yBDJgB;ACelB;;AATA;EACE,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAYF;;AARA;EACE,WAAA;EACA,gBAAA;AAWF","sourcesContent":["// _variables.scss\n\n$color-background:#B1B5C8;\n$color-hover: #FEFFBE;\n$color-text: #503F47;\n$color-dark: #000411;\n\n\n\n  \n  @font-face {\n    font-family: 'CaviarDreams';\n    src: url('../src/assets/fonts/CaviarDreams.ttf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n\n  @font-face {\n    font-family: 'NewYork';\n    src: url('../src/assets/fonts/NewYork.otf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n  // Variable pour utiliser la police dans tout le projet\n  $font-primary: 'NewYork', sans-serif;\n  $font-secondary: 'CaviarDreams', sans-serif;\n\n\n\n","@import '../../variables';\n\n.contactWrap {\n  display: flex;\n  height: 100vh;\n  width: 100vw; \n  background-color: $color-background;\n}\n.contactForm {\n  width: 90%; \n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 2rem;\n}\n\n\n.contact-form {\n  width: 100%;\n  max-width: 600px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
