import React from "react";

function error() {
    return(
        <div>
            <p>this is the error page</p>
        </div>
        
    )
}
    

export default error