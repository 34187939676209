import React from 'react';
import './CV.scss';  // Assurez-vous d'avoir un fichier de style

function CV() {
  return (
    <div className='CVWrapper'>
      <img 
        src="https://i.imgur.com/LcVllxf.jpeg" 
        alt="CV" 
        className='cv-image'
      />
    </div>
  );
}

export default CV;

