// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/CaviarDreams.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/NewYork.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "CaviarDreams";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
@font-face {
  font-family: "NewYork";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
  font-weight: 400;
  font-style: sans-serif;
}
.menuWrapper {
  flex: 0 0 62%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B1B5C8;
}

.menuWrapper ul {
  list-style-type: none;
  padding: 0;
}

.menuWrapper li {
  margin: 20px 0;
  text-align: left;
}

.menuWrapper a {
  text-decoration: none;
  color: #503F47;
  font-size: calc(12px + 123 * (100vw - 375px) / 1065);
  transition: color 0.3s ease;
}

.menuWrapper a:hover {
  color: #FEFFBE;
}

@media (max-width: 640px) {
  .menuWrapper a {
    font-size: 50px;
  }
  .menuWrapper {
    align-items: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/_variables.scss","webpack://./src/components/Menu/Menu.scss"],"names":[],"mappings":"AAUE;EACE,2BAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACTJ;ADYE;EACE,sBAAA;EACA,+DAAA;EACA,gBAAA;EACA,sBAAA;ACVJ;AAVA;EACI,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBDJc;ACgBlB;;AATE;EACE,qBAAA;EACA,UAAA;AAYJ;;AATE;EACE,cAAA;EACA,gBAAA;AAYJ;;AATE;EACE,qBAAA;EACA,cDjBS;ECkBT,oDAAA;EACA,2BAAA;AAYJ;;AATE;EACE,cDxBU;ACoCd;;AATE;EACE;IACE,eAAA;EAYJ;EATE;IACE,mBAAA;EAWJ;AACF","sourcesContent":["// _variables.scss\n\n$color-background:#B1B5C8;\n$color-hover: #FEFFBE;\n$color-text: #503F47;\n$color-dark: #000411;\n\n\n\n  \n  @font-face {\n    font-family: 'CaviarDreams';\n    src: url('../src/assets/fonts/CaviarDreams.ttf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n\n  @font-face {\n    font-family: 'NewYork';\n    src: url('../src/assets/fonts/NewYork.otf') format('opentype');\n    font-weight: 400;\n    font-style: sans-serif;\n  }\n  // Variable pour utiliser la police dans tout le projet\n  $font-primary: 'NewYork', sans-serif;\n  $font-secondary: 'CaviarDreams', sans-serif;\n\n\n\n","@import '../../variables';\n.menuWrapper {\n    flex: 0 0 62%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: $color-background;\n  }\n  \n  .menuWrapper ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .menuWrapper li {\n    margin: 20px 0;\n    text-align: left;\n  }\n  \n  .menuWrapper a {\n    text-decoration: none;\n    color: $color-text; \n    font-size: calc(12px + (135 - 12) * ((100vw - 375px) / (1440 - 375)));\n    transition: color 0.3s ease;\n  }\n  \n  .menuWrapper a:hover {\n    color: $color-hover;\n  }\n  \n  @media (max-width: 640px) {\n    .menuWrapper a {\n      font-size: 50px;\n    }\n\n    .menuWrapper{\n      align-items: normal;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
